
/*====================================================================================*/
 /* 01 RESET
/*====================================================================================*/

/*Colors*/
--primary-gray--#4a4a4a--
--primary-red--#c44747--
 

* { margin:0px; padding:0px; }

article,aside,details,figcaption,figure,
    footer,header,hgroup,menu,nav,section { 
            display:block;
    }

h1,h2,h3,h4 { margin-top:0px; }

ul li { list-style:none; }
ul.list li { list-style:disc; font-size: 18px; }
ul.list li a { text-decoration: underline}
.list-group-item-heading {font-size: 18px}
.list-group-item-heading a {text-decoration: underline}
.dropdown:hover .dropdown-menu {
    display: block;
 }


/*====================================================================================*/
 /* 02 GLOBAL SETTINGS
/*====================================================================================*/

@font-face {
    font-family: 'Ubuntu Light';
    src: url('type/ubuntu-l-webfont.eot');
    src: url('type/ubuntu-l-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/ubuntu-l-webfont.woff') format('woff'),
         url('type/ubuntu-l-webfont.ttf') format('truetype'),
         url('type/ubuntu-l-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu Med';
    src: url('type/ubuntu-m-webfont.eot');
    src: url('type/ubuntu-m-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/ubuntu-m-webfont.woff') format('woff'),
         url('type/ubuntu-m-webfont.ttf') format('truetype'),
         url('type/ubuntu-m-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu Bold';
    src: url('type/ubuntu-b-webfont.eot');
    src: url('type/ubuntu-b-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/ubuntu-b-webfont.woff') format('woff'),
         url('type/ubuntu-b-webfont.ttf') format('truetype'),
         url('type/ubuntu-b-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Light';
    src: url('type/proximanova-light-webfont.eot');
    src: url('type/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/proximanova-light-webfont.woff') format('woff'),
         url('type/proximanova-light-webfont.ttf') format('truetype'),
         url('type/proximanova-light-webfont.svg#proxima_novalight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Reg';
    src: url('type/proximanova-regular-webfont.eot');
    src: url('type/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/proximanova-regular-webfont.woff') format('woff'),
         url('type/proximanova-regular-webfont.ttf') format('truetype'),
         url('type/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Semi-bold';
    src: url('type/proximanova-semibold-webfont.eot');
    src: url('type/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('type/proximanova-semibold-webfont.woff') format('woff'),
         url('type/proximanova-semibold-webfont.ttf') format('truetype'),
         url('type/proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
	font-family:Proxima Nova Light, Helvetica, Arial;
	font-size:15px;
}

a { color:#c44747; }
a:hover { color:#ea6868; text-decoration:none; }

.boldItalic { 
	font-weight:bold; 
	color:#565656;
	font-size:17px;
	font-style:italic;
}

.genericSection { padding:80px 0px 80px 0px; }
.genericSection h1{ font-size: 35px; text-transform: uppercase}
.genericSection h3{ font-size: 18px;font-family: Ubuntu Bold;color: #545454;text-transform: uppercase;text-align: left;margin-bottom: 10px;}
.genericSection p{ font-size: 18px }

h1 {
	font-family:Ubuntu Med, Helvetica, Arial;
	font-size:107px;
}

h2 {
	font-family:Ubuntu Med, Helvetica, Arial;
	font-size:41px;
}

h3 {
	font-family:Ubuntu Light, Helvetica, Arial;
	font-size:28px;
	text-align:center;
	color:#606060;
	margin-bottom:20px;
	letter-spacing:1px;
}

h3 span { font-family:Ubuntu Bold; color:#c44747; }


h4 {
	font-family:Ubuntu Med, Helvetica, Arial;
	color:#545454;
	letter-spacing:1px;
}

h5 { 
	font-family:Proxima Nova Light, Helvetica, Arial;
	line-height:28px;
	font-size:21px; 
	text-align:center; 
	letter-spacing:1px;
	margin-bottom:60px;
}

h6 { 
	font-size:18px; 
	font-family:Ubuntu Bold;
	color:#545454;
}

/** BUTTONS **/
.button {
	background:transparent;
	font-family:Ubuntu Bold;
	color:white;
	border-style:solid;
	border-width:2px;
	border-radius:4px;
	padding:12px 60px 12px 60px;
	display:inline-block;
	-webkit-transition: background 0.3s linear;
  -moz-transition: background 0.3s linear;
  -ms-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
}
.button-white {
	background:#ffffff;
	font-family:Ubuntu Bold;
	color:#333;
	border-style:solid;
	border-width:2px;
	border-radius:4px;
	padding:12px 60px 12px 60px;
	display:inline-block;
	-webkit-transition: background 0.3s linear;
  -moz-transition: background 0.3s linear;
  -ms-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
	text-shadow: none;
}
.button-white:hover {
	background:transparent;
	color:white;
	text-shadow: none;
}

.subHeadBtn-table {
	width:100%; 
	margin-top:-80px; 
	position:absolute;
}

.subHeadBtn-table td { text-align:center; }

.button:hover { 
	background-color:white; 
	text-shadow: none;
	color:#c44747;
}

.button.grey {
	border-color:#c8c8c8;
	color:#c8c8c8;
}

.button.grey.video {
	margin-bottom:0px;
}

.button.grey.current, .button.grey:hover {
	background-color:#c44747;
	color:white;
	border-color:#c44747;
	text-decoration:none;
}

.button.smallGrey {
	font-family:Ubuntu Bold;
	font-size:11px;
	padding:5px 10px 5px 10px;
	background-color:#e1e1e1;
	border-radius:3px;
	color:#848484;
	border:none;
}

.button.smallGrey:hover { color:white; background-color:#c44747; }

.button.red {
	color:#c44747;
	border-color:#c44747;
}

.button.red:hover {
	background-color:#c44747;
	color:white;
}

/* to center button */
.wrapper { 
	text-align:center; 	
	margin-top:0px;
	margin-bottom:60px; 
}

/** FORM ELEMENTS **/

input {
	background:transparent;
	border-style:solid;
	border-width:2px;
	border-color:#d0d0d0;
	border-radius:4px;
	padding:12px;
	width:100%;
}

input[type=checkbox] {
	width:auto;
	float:left;
	margin-right:20px;
}

.contactForm input {
	margin-bottom:20px;
	float:left;
	width:49%;
}

.contactForm .button { width:35%; float:none; }

#contact-us input { margin-bottom:20px; }
#contact-us .button { width:35%; float:none; }
.screen-reader-text { display:none; }
.error { float:left; margin-bottom:30px; margin-top:-10px; }

textarea { 
	width:100%; 
	height:181px;
	background:transparent;
	border-style:solid;
	border-width:2px;
	border-color:#d0d0d0;
	border-radius:4px;
	padding:12px;
	margin-bottom:20px;
}

.formDropdown {
	-webkit-appearance: none;
	-moz-appearance: none; 
	background: url('../images/dropDownArrow.png')  no-repeat;
	background-position: right center; 
	border-style:solid;
	border-width:2px;
	border-color:#d0d0d0;
	border-radius:4px;
	padding:11px;
	width:49%;
	text-indent: 0.01px;
 	text-overflow: "";
}

.formDropdown option {
	background-color:#233036;
	color:#a1b1b8;
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:#162026;
	background-image:none;
}

.map iframe { width:100%; margin-bottom:-10px;}

.subscribe { width:auto; }

/*====================================================================================*/
 /* 03 HEADER 
/*====================================================================================*/

header a { font-family:Ubuntu Bold; }

.navbar.navbar-default.navbar-fixed-top { 
	border-bottom:none; 
	box-shadow: 0px 0px 3px #9c9c9c;
	z-index:1000;
	background-color: #4a4a4a;
}

.navbar-brand img { margin-right:20px; margin-top: 0px}
.navbar-brand { letter-spacing:2px; margin-top:0; } 

.nav.navbar-nav { float:right; margin-top: 20px}

.nav.navbar-nav li a {
	color:#ffffff;
	font-size:15px;
	letter-spacing:2px;
	padding-top:35px;
	padding-bottom:35px;
	margin-right:8px;
}

.nav.navbar-nav li a:hover {
	background-color:transparent;
	color:#c44747;
}

.nav.navbar-nav .dropdown .dropdown-menu {
	box-shadow:none;
	background-color:#c44747;
	border:none;
	border-radius:0px;
	padding:0px;
	margin-top:0px;
}

.nav.navbar-nav .dropdown .dropdown-menu li a {
	font-family:Proxima Nova Semi-bold;
	margin:0px 0px 0px 0px;
	padding:14px;
	color:#ffffff;
	letter-spacing:0px;
	font-size:14px;
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:#172126;
	text-transform: uppercase
}

.nav.navbar-nav .dropdown .dropdown-menu li a:hover { background-color:#4a4a4a; color:#ccc; }

/** YAMM MEGA MENU **/
.nav.navbar-nav .dropdown .dropdown-menu .yamm-content a {
	padding:0px;
	border:none;
}

.nav.navbar-nav .dropdown .dropdown-menu .yamm-content a:hover {
	background-color:#182227; color:#7f9baa;
}

.yamm-content {
	color:#455c68;
	font-size:14px;
	background-color:#c44747;
	border-style:solid;
	border-width:1px;
	border-color:#d7d7d7;
}

.yamm-content h4 { 
	color:#455c68; 
	font-size:16px;
	margin-bottom:20px;
}
.yamm-content h4 a{ 
	text-decoration: underline;
}
.googleMap { border:none; }

/* Dropdown Submenu */
    .nav.navbar-nav .dropdown .dropdown-menu .dropdown-submenu {
        position:relative;
        overflow: visible;
    }

    .nav.navbar-nav .dropdown .dropdown-menu .dropdown-submenu > .dropdown-menu {
        left:161px;
        margin-top:-39px;
        margin-left:-1px;
        -webkit-border-radius:0px;
        -moz-border-radius:0px;
        border-radius:0px;
        display:none;
        background-color:#1f2b33;
    }
    
    .nav.navbar-nav .dropdown .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
        display:block;
    }

.nav.navbar-nav .current { 
	color:#c44747; 
	box-shadow: inset -4px -8px 0px -4px #c44747;
}

/** SEARCH **/
.nav.navbar-nav .search { margin-right:-12px; }

.nav.navbar-nav .search li a {
	margin-right:20px;
	margin-left:20px;
}

.verticalDivider {
	height:90px;
	width:1px;
	background-color:#d5d5d5;
}

.searchDropdown {
	width:350px;
	height:auto;
	background-color:#f8f8f8;
	border-top-style:solid;
	border-top-width:1px;
	border-top-color:#d7d7d7;
}

.searchForm input {
	margin:20px;
	width:88.5%;
	background: url(../images/searchIcon.png) no-repeat;
	background-position:95% 50%;
	color:#171f23;
}

.navbar-toggle { margin-top:22px; }

/** HEADER TOP BAR **/
.topBar {
	padding-top:6px;
	padding-bottom:6px;
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:#cdcdcd;
}

.italicSmall.top {
	margin-top:3px;
	margin-bottom:0px;
	float:left;
	color:#848484;
}

.topBar .socialIcons { margin-bottom:0px; }
.topBar .socialIcons li img { opacity:0.2; width:25px; }
.topBar .socialIcons li img:hover { opacity:1.0; }

.topBar.shaded {
	background-color:#eeeeee;
	border:none;
}

.topBar.thin {
	position:absolute;
	padding:0;
	height:5px;
	width:100%;
	background-color:#c0d4de;
}

/*====================================================================================*/
 /* 04 SUBHEADERS
/*====================================================================================*/

/* 04.1 SUBHEADER SPLIT */
/*====================================*/
.subHeader-index.split {
	position: relative;
	margin-top:100px;
	height:450px;
}

.leftBlock::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(74,74,74,.7);
	z-index: 3;
}
.leftBlock {
	position: relative;
	width: 50%;
	height: 100%;
	background:url(../images/main/DV_new.jpg) center center no-repeat;
	background-size: cover;
	display:flex;
	float:left;
	text-align: center;
	text-shadow: 2px 2px 2px #000;
	z-index: 9;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	flex-direction: column
}

.leftBlock h2 {     
	color: #fff;
  text-shadow: 1px 1px 2px #000;
  letter-spacing: -1px;
  font-weight: 700;
  line-height: 1em;
	display: inline-block;
} 

.leftBlock p {
	font-size:32px;
	letter-spacing:1px;
	color:white;
}
.rightBlock::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #c44747b3;
	z-index: 3;
}
.rightBlock {
	position: relative;
	width: 50%;
	height: 100%;
	background:url(../images/main/VR_new.jpg) center center no-repeat;
	background-size: cover;
	display:flex;
	float:left;
	text-align: center;
	text-shadow: 2px 2px 2px #000;
	z-index: 9;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	flex-direction: column
}

.rightBlock h2 {     
	color: #fff;
  text-shadow: 1px 1px 2px #000;
  letter-spacing: -1px;
  font-weight: 700;
  line-height: 1em;
} 


.leftBlock p {
	font-size:25px;
	letter-spacing:1px;
	color:white;
}
.rightBlock p {
	font-size:25px;
	letter-spacing:1px;
	color:white;
}

.wrapperBlock {
	position: relative;
	z-index: 10;
	padding: 0px 15px;
}

.rightBlock .container { width:500px;}

.subHeaderList li {
	font-family:Proxima Nova Semi-bold;
	font-size:18px;
	display:inline;
	margin-left:45px;
	margin-right:45px;
}

/* 04.2 SUBHEADER CLASSIC */
/*====================================*/
.subHeader.classic {
	padding:75px 0px 70px 0px;
	margin-top:90px;
	background-color:#4a4a4a;
	/*background: url('../images/subHeaderBg_blue.png'); */
	background-repeat:no-repeat;
	background-size:cover;
	color:white;
}

.subHeader.classic.video {
	background: url('../images/redSquare.png');
	background-repeat:repeat;
	text-align:center;
	padding:180px 0px 180px 0px;
}

.subHeader.classic.video .col-lg-8 { margin-top:0px; }
.subHeader.classic .col-lg-8 { margin-top:70px;}

.subHeader.classic h1 { font-size:40px; float:left;text-transform: uppercase }
.subHeader.classic.video h1 { float:none; }

.subHeader.classic h1 span {
	font-family:Proxima Nova Semi-bold;
	font-size:30px;
	color:#c44747;
	font-style:italic;
	margin-left:20px;
}

.subHeader.classic p { font-size:20px; }

.subHeader.classic .subHeadBtn {
	position:relative;
	margin-top:0px;
	margin-left:0px;
}

.subHeader.classic.video .sliderControls span {
	margin-top:-150px;
	opacity:0.5;
}

/** SUBHEADER SEARCH FORM **/
.subHeader .searchForm input {
	float:right; 
	width:300px; 
	color:#8b9da8; 
	margin-top:25px; 
	margin-right:0px;
}
.subHeader::before{
	content:'';
	display: block;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: rgba(102, 102, 102, .88);
}
.subHeader.services {
	padding:75px 0px 70px 0px;
	margin-top:100px;
	background: url(../images/banner/banner_01.jpg); 
	background-repeat:no-repeat;
	background-size:cover;
	position: relative;
}
.subHeader.products {
	padding:75px 0px 70px 0px;
	margin-top:100px;
	background: url(../images/banner/banner_02.jpg); 
	background-repeat:no-repeat;
	background-size:cover;
	position: relative;
}
.subHeader.locations {
	padding:75px 0px 70px 0px;
	margin-top:100px;
	background: url(../images/banner/banner_03.jpg); 
	background-repeat:no-repeat;
	background-size:cover;
	position: relative;
}
.subHeader.about {
	padding:75px 0px 70px 0px;
	margin-top:100px;
	background: url(../images/banner/banner_04.jpg); 
	background-repeat:no-repeat;
	background-size:cover;
	position: relative;
}
.subHeader.contact {
	padding:75px 0px 70px 0px;
	margin-top:100px;
	background: url(../images/banner/banner_05.jpg); 
	background-repeat:no-repeat;
	background-size:cover;
	position: relative;
}
 .title span { 
	font-size:40px; 
	float:left;
	text-transform: uppercase; 
	color: #ffffff;
	font-family: Ubuntu Med, Helvetica, Arial;
	font-weight: 500;
	line-height: 1.1;
	position: relative;
}
/*====================================================================================*/
 /* 05 PORTFOLIO GENERAL 
/*====================================================================================*/

/* 05.1 PORTFOLIO GENERAL */
/*====================================*/
.overlay {
	background-color:#c44747;
	opacity:0.9;
	display:block;
	width:100%;
	position:absolute;
	height:100%;
	box-shadow:0px 0px 2px #cdcdcd;
	border-style:solid;
	border-width:5px;
	border-color:white;
	text-align:center;
	display:none;
}

.overlay h4 { 
	margin-top:30%;
	color:white;
	pointer-events:none;
}

.italicSmall {
	font-size:13px; 
	font-style:italic; 
	letter-spacing:0px; 
	margin-right:15px;
}

.portfolioCat_list { margin-top:-8px; margin-bottom:40px; text-align:center;  }

.portfolioCat_list li {
	display:inline;
	margin-right:10px;
}

.portfolioCat_list li a { margin-bottom:10px; }

/* 05.2 PORTFOLIO FULL WIDTH */
/*====================================*/

.workFullWidth { padding:80px 0px 0px 0px; background-color:white; }

#screenshots { margin-top:40px; }
#screenLeft, #screenRight { margin-top:80px; opacity:0; max-width:100%;}
#screenRight { float:right; }

#screenMiddle {
	opacity:0;
	position:absolute;
	display:block;
	margin-left:23%;
	margin-top:-340px;
	max-width:100%;
}

.workFullWidth_list .image { width:25%; margin-left: -1px; float:left; }
.workFullWidth_list { margin-bottom:20px; }
.workFullWidth_list img {  width:100%; display:block; }
.workFullWidth_list .overlay { border:none; }


/* 05.3 PORTFOLIO COLUMN */
/*====================================*/

.workColumn { padding:80px 0px 0px 0px; }

.portfolioItem { 
	position:relative;
	display:block;
	margin-bottom:30px; 
}

.col-lg-3 { width:292px; }
.col-lg-3 .portfolioItem { margin-bottom:10px; }

.portfolioImg {
	max-width:100%;
	box-shadow:0px 0px 2px #cdcdcd;
	border-style:solid;
	border-width:5px;
	border-color:white;
}

.portfolioDetails, .shopItemDetails {
	margin-top:13px;
	margin-bottom:35px;
	padding:13px 13px 0px 13px;
	border-style:solid;
	border-width:1px;
	border-color:#d0d0d0;
	border-radius:3px;
}

.portfolioDetails h4, .shopItemDetails h4 {
	font-family:Ubuntu Bold;
	font-size:14px;
}

.portfolioDetails p {
	font-size:13px;
	font-style:italic;
	text-align:left;
}

.tagList li { 
	display:inline;
	margin-right:5px;
}

/* 05.4 PORTFOLIO SINGLE */
/*====================================*/

.projectDetailsList li { margin-bottom:10px; }

.projectDetailsList .button { margin-right:10px;}
.projectDetailsList .button.smallGrey:hover { background-color:#e1e1e1; color:#848484; }

/* 05.5 PORTFOLIO WIDGET SECTION */
/*====================================*/

.widgets {
	padding:50px 0px 80px 0px;
}

.dividerWidget{
	width:100%;
	height:2px;
	background-color:#cacaca;
	margin-bottom:30px;
}

.clientList li {
	display:inline-block;
	margin-right:21px;
	margin-bottom:20px;
	padding:40px 62px 40px 62px;
	border-style:solid;
	border-width:1px;
	border-radius:3px;
	border-color:#b4b4b4;
}

.clientList li:nth-child(3n+0) { margin-right:0px; }

.tweetList li {
	margin-bottom:18px;
}

.tweetList span {
	font-size:12px;
	font-style:italic;
	color:#696969;
}

.relatedProjectList li {
	display:inline-block;
	margin-right:21px;
	margin-bottom:20px;
	width:168px;
}

.progressBar {
	border-style:solid;
	border-width:1px;
	border-color:#a9a9a9;
	border-radius:3px;
	height:37px;
	padding:5px;
	margin-bottom:19px;
}

.progressBar span { 
	float:left; 
	margin-right:5px; 
	width:15%;
	text-align:center;
}

.bar {
	float:left;
	height:25px;
	background-color:#c44747;
}

.bar.full { width:83.7%; }
.bar.half { width:42%; }
.bar.threeQuarters { width:60%; }
.bar.quarter { width:21%; }


/*====================================================================================*/
 /* 06 SERVICES SECTION
/*====================================================================================*/

.serviceContent { padding:80px 0px 80px 0px; }

.textureLip {
	width:100%;
	height:19px;
	background-color:white;
	background: url('../images/textureLip.png');
	box-shadow:0px -3px 3px #d7d7d7;
	position:relative;
}

.row.services { margin-top:50px; }
.row.services .col-lg-4 { margin-bottom:20px; }

.services3Col_big {
	padding:80px 0px 80px 0px;
	text-align:center;
	background: url('../images/greyTexture.png');
}

.services3Col_big .row.services { opacity:0; }

.services3Col_small {
	padding:80px 0px 80px 0px;
	background: url('../images/greyTexture.png');
}
.services img {border: 1px solid #0006}
.serviceText { 
	float:right;
	width:260px;
}

/*====================================================================================*/
 /* 07 ABOUT
/*====================================================================================*/ 

.aboutTeam {
	padding:80px 0px 80px 0px;
	text-align:center;
}
.aboutTeam h1  {font-family:Ubuntu Bold; color:#545454; font-size: 30px; margin-bottom: 10px}
.aboutTeam h1 span {color:#c44747;}
.aboutTeam h4 { color:#545454; }
.aboutTeam h4 span { font-family:Ubuntu Bold; color:#c44747; font-size: 24px; margin-bottom: 10px }
.aboutTeam img { margin-bottom:20px; max-width:100%;}
.aboutTeam .socialIcons { margin:0 auto; float:none;}

.skills { padding:80px 0px 80px 0px; background-color:#c44747; }
.skills p { float:left; color:white; font-size:21px; margin-top:-105px;}
.skillDivider {margin-top:-200px; margin-left:100px; }
.skills h4 {color:white; margin-top:10px;}

.skillsList li {
	display:inline-block;
	width:180px;
	margin-right:53px;
	text-align:center;
}

.skillsList li:nth-child(5) { margin-right:0px; }


/*====================================================================================*/
 /* 08 PROMO BOX
/*====================================================================================*/

.promoBox {
	padding:80px 0px 60px 0px;
	background-color:#293941;
	color:white;
}

#mailIcon { float:left; margin-right:10px; }

.promoBox h4 { color:white; }

.subscribeForm .input { 
	color:white; 
	margin-right:5px; 
	margin-bottom:10px;
	width:340px; 
	border-color:white; 
}

.button.subscribe { border-color:white; }


/*====================================================================================*/
 /* 09 FEATURES
/*====================================================================================*/

.features { padding:80px 0px 80px 0px; background-color:white; }

.featuresimg { max-width:100%; margin-top:0px; border: 1px solid #0006}

.features ul { margin-top:35px; opacity:0;}
.features ul li { margin-bottom:25px; }


/*====================================================================================*/
 /* 10 BLOG
/*====================================================================================*/

/* 10.1 BLOG GENERAL */
/*====================================*/
.blogThumb, .blogThumb img {
	margin-top:0px;
	max-width:100%;
}
.blogThumb h1 {
	font-size: 35px;
	text-transform: uppercase;
}
.blogPostClassic p {
	font-size: 18px;
}
.thumbBar {
	width:100%;
	height:5px;
	background-color:#c44747;
	margin-bottom: 20px
}

.triangle {
	width: 0; 
	height: 0; 
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-top: 12px solid #c44747;
	margin:0 auto;
}

.button.blog {
	background-color:#ffffff;
	color: #000;
	text-shadow: none;
	padding:8px 20px 8px 20px;
}

.button.blog:hover {
	background-color:#c44747;
	color:white;
}

.blogDetails { 
	margin-top:10px; 
	border-style:solid;
	border-width:1px;
	border-color:#cacaca;
	border-radius:3px;
	width:100%;
	text-align:center;
}

.blogDetails td {
	font-family:Ubuntu Bold;
	font-size:12px;
	width:33.6%;
	height:40px;
	border-color:#cacaca;
}

.blogDetails td a { color:#ababab;} 
.blogDetails td a:hover { opacity:0.7; } 

.blogIcon { margin-right:6px; }


/* 10.2 BLOG CLASSIC */
/*====================================*/

.blogClassic { padding:80px 0px 80px 0px; }
.blogPostClassic h3 { 
	font-size: 18px;
	font-family: Ubuntu Bold;
	color: #545454;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 10px
}
.blogPostClassic h4 { font-size:32px; }
.blogPostClassic h4 a { color:#545454; }
.blogPostClassic { margin-bottom:40px; max-width:100%; }
.blogPostClassic .videoPost { height:450px; }
.blogPostClassic img { width: 100%; margin-bottom: 20px }

.sidebar .searchForm input { 
	width:100%;
	margin:0px 0px 40px 0px;
	background-color:white; 
	border-style:solid;
	border-width:2px;
	border-color:#cacaca;
	color:#b7b7b7;
}

.categoryList { margin-bottom:40px; padding-left: 0; }
.categoryList li { 
	margin-bottom:12px; 
	border-bottom:1px solid #cacaca;
	padding:5px 5px 8px 5px;
	text-transform: uppercase;
	font-weight: 500;
}

.catNum { 
	font-family:Ubuntu Bold;
	font-size:12px;
	float:left; 
	padding:3px 7px 3px 7px; 
	background-color:#c44747;
	color:white;
	border-radius:3px;
	margin-right:8px;
}

.catNum:hover { color:white; }
.categoryList .button.smallGrey { float:right; }

.topPostList li { margin-bottom:30px; }
.topPostList h1 { font-size:14px; margin-bottom:3px; }
.topPostList .sidebarThumb { float:left; margin-right:15px; }

.blogClassic .tagList { margin-bottom:25px; }
.sidebar .tagList li a {margin-bottom:11px;}


/* 10.3 BLOG CREATIVE */
/*====================================*/

.blogCreative { padding:20px 0px 80px 0px; }
.blogCreative h4 a { font-size:28px; color:white; }
.blogCreative h4 a:hover { color:#c44747; }

.blogPostCreative { float:left; width:292px; margin-top:30px; }
.blogPostCreative.wide { width:584px; }

.date {
	margin-top:-65px;
	padding:10px 0px 10px 0px;
	background-color:#c44747;
	color:white;
	text-align:center;
	width:70px;
	position:relative;
	z-index:10;
}

.date span { 
	font-size:25px; 
	font-family:Ubuntu Bold; 
	line-height:20px; 
}

.blogExcerptCreative {
	padding:20px 10px 25px 10px;
	background-color:#293941;
	color:white;
}

.blogCreative .blogPostCreative .dividerWidget { opacity:0.2; }

.blogDetailsCreative p { 
	font-family:Ubuntu Bold;  
	font-size:12px; 
	color:#748b96; 
}

.blogPostCreative .quotePost { padding:50px 0px 100px 0px; }
.blogPostCreative .bxsliderBlog { margin-bottom:-458px; }


/* 10.4 BLOG MASONRY */
/*====================================*/

.blogMasonry {
	text-align:center;
	padding:20px 0px 80px 0px;
	background-color:white;
}

.blogPostMasonry { float:left; width:292px; margin-top:30px; }

.blogMasonry h4 a { color:#545454;}
.blogMasonry h4 a:hover { color:#2d2d2d; text-decoration:none; }

.blogExcerptMasonry {
	padding:20px 10px 25px 10px;
	margin-top:20px; 
	border-style:solid;
	border-width:1px;
	border-color:#cacaca;
	border-radius:3px;
}

.videoPost { margin-bottom:-6px; width:100%; height:200px; }
.quotePost { 
	background-color:#c44747; 
	padding:14px 0px 41px 0px; 
	display:block; 
	color:white;
	text-align:center;
	-webkit-transition: background 0.3s linear;
    -moz-transition: background 0.3s linear;
    -ms-transition: background 0.3s linear;
    -o-transition: background 0.3s linear;
    transition: background 0.3s linear;
}
.quotePost span { font-size:40px; font-family:Georgia; }
.quotePost:hover { background-color:#293941; color:white; }

.bxsliderBlog { margin-bottom:-410px; }
.bx-wrapper .bx-pager { bottom: 30px; }
.bx-wrapper .bx-pager.bx-default-pager a { background:white; }


/* 10.5 BLOG SINGLE */
/*====================================*/

.quoteBox { 
	background-color:#f5f5f5; 
	padding:25px 25px 10px 25px;
	border-radius:3px;
	font-family:Ubuntu Bold;
	color:#545454;
	font-size:18px;
	border-left-style:solid;
	border-left-color:#c44747;
	font-style: italic;
}

.comment.reply {
	margin-left:130px;
}

.commentText {
	border-style:solid;
	border-width:1px;
	border-radius:3px;
	border-color:#cdcdcd;
	border-left-color:#c44747;
	border-left-width:4px;
	padding:20px;
	float:left;
	margin-bottom:20px;
}

.arrow-left {
	width: 0; 
	height: 0; 
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent; 
	border-right:8px solid #c44747; 
	float:left;
	margin-left:-8px;
	margin-top:40px;
}

.commentImage { max-width:100%; }

.commentText h1 {
	font-size:12px;
	color:#c44747;
}
.commentText p { font-size:13px; }

.commentText .commentFooter {
	float:left;
	font-family:Ubuntu Bold; 
	font-size:11px;
	color:#ababab;
}

.commentText .commentFooter.replyIcon:hover { opacity:0.7; }

.commentText .commentFooter.replyIcon { float:right; }

/*====================================================================================*/
 /* 11 PAGES
/*====================================================================================*/

.comingSoonForm input { margin-bottom:10px; }
.container.coming-soon { max-width:100%; }

.content404 { padding:80px 0px 80px 0px; }

.content404 h1 { 
	margin:0 auto;
	margin-bottom:30px;
	font-size:119px;
	text-align:center;
	color:#a6a6a6;
	border-style:solid;
	border-color:#a6a6a6;
	border-radius:5px;
	width:250px;
}

.content404 p {
	font-family:Ubuntu Bold;
	text-align:center;
	color:#a6a6a6;
}

.content404 ul { text-align:center; }

.content404 ul li {
	display:inline-block;
	margin-right:7px;
	margin-bottom:7px;
}

/** TABS **/

.tabs { margin-bottom:-2px;}

.tabs li {
	display:inline;
	margin-right:5px;
}

.button.grey.tab { 
	border-bottom-left-radius:0px;
	border-bottom-right-radius:0px;
	padding-left:2%;
	padding-right:2%;
}

.tabContent {
	border-style:solid;
	border-width:2px;
	border-radius:3px;
	border-color:#c8c8c8;
	padding:25px;
}
.tabContent h4 { font-size:14px; }

/** ACCORDIAN **/

.accordianTab {
	border-style:solid;
	border-width:2px;
	border-radius:3px;
	border-color:#c8c8c8;
	margin-bottom:10px;
	padding:5px;
}

.accordianLink {
	background-image: url('../images/plusIcon.png');
	background-repeat:no-repeat;
	height:45px;
	padding-left:55px;
}

.accordianLink p { padding-top:13px; }

.accordianIcon:hover { opacity:1.0; }

.accordianTab .accordianLink { 
	cursor:pointer;
	font-family:Ubuntu Bold;
	color:#a7a7a7; 
}

.accordianTab .accordianLink:hover { color:#c44747; }

.accordianContent { display:none; padding:15px 20px 0px 20px; }

/** ALERT BOXES **/

.alertBox {
	border-style:solid;
	border-radius:3px;
	padding:18px 18px 8px 18px;
	margin-bottom:15px;
}

.alertBox img { float:left; margin-right:10px; margin-top:-3px; }

.success { 
	background-color:#e2f1db;
	border-color:#8dca70;
	color:#73a45b;
}

.success h4 { color:#73a45b; }

.error {
	background-color:#f1d9d9;
	border-color:#c44747;
	color:#c44747;
}

.error h4 { color:#c44747; }

.info {
	background-color:#d9e7f1;
	border-color:#70a3ca;
	color:#70a3ca;
}

.info h4 { color:#70a3ca; }

.warning {
	background-color:#ebe8bc;
	border-color:#aca530;
	color:#aca530;
}

.warning h4 { color:#aca530; }

/** PRICING TABLES **/

.pricingTable { text-align:center; }

.pricingHeader {
	background-color:#c44747;
	padding:11px 11px 1px 11px;
}

.pricingHeader h4 { color:white; }

.pricingAmount {
	margin-top:-12px;
	background-color:#293941;
	color:white;
	padding:28px 28px 13px 28px;
	position:relative;
	z-index:-1;
}

.pricingAmount h2 span { font-size:17px; }

.pricingDetails {
	border-style:solid;
	border-width:1px; 
	border-color:#bbbbbb;
	border-top-style:none;
	border-bottom-style:none;
}

.pricingDetails li { 
	padding:20px;
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:#c5c5c5;
}

/** ITEM TABLE **/

.itemTable { width:100%; }
.itemTable td, .itemTable th {
	border-style:solid;
	padding:13px;
	border-width:1px; 
	border-color:#bbbbbb;
	font-size: 18px
}

.itemTable tr:nth-child(even) {
    background-color: #f5f5f5;
}

/*====================================================================================*/
 /* 12 SHOP
/*====================================================================================*/
.shop { padding:85px 0px 85px 0px; }

.button.cart { 
	padding-left:25px; 
	padding-right:25px; 
	float:right;
	margin-top:30px;
}

.shop .italicSmall { float:left; }

.formDropdown.sort {
	float:right;
	width:230px;
}

.formDropdown.qty { width:70px; }

.row.shopList { margin-top:80px; }

.shopPrice {
	color:#c44747;
	font-size:21px;
	margin-top:-7px;
}

.shopPrice span {
	font-size:14px;
	color:#909090;
	text-decoration: line-through;
}

.shopItem { 
	position:relative;
	display:block;
	margin-bottom:30px; 
}

.col-lg-3 .shopItem { margin-bottom:10px; }

.shopItem .overlay h4 { margin-top:50%; }

.shopImg { max-width:100%; }

.button.smallGrey.sale {
	background-color:#c44747;
	color:white;
	position:absolute;
	margin-top:5px;
	margin-left:5px;
}

.pageList li { display:inline; }
.pageList li .current { background-color:#c44747; color:white; }

.shopInput { width:44%; margin-bottom:15px; }
.button.red.shopForm { width:30%; float:right; }

.shopReview {
	border-top-style:solid;
	border-top-width:1px;
	border-top-color:#d4d4d4;
	padding-top:30px;
	padding-bottom:40px;
}

.shopReview h1 {
	font-size:12px;
	color:#c44747;
}

.reviewFooter {
	float:left;
	font-family:Ubuntu Bold; 
	font-size:11px;
	color:#ababab;
}

.itemTable.shop { margin-bottom:60px; }

.itemTable.shop tr:nth-child(even) {
    background-color: white;
}

.shippingForm { margin-bottom:60px; }

.totalsList li {
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:#d4d4d4;
	padding-top:10px;
	padding-bottom:10px;
}

.totalsList li span { float:right; }

.confirmOrder {
	background-color:#f6f6f6;
	text-align:center;
	padding:45px;
}

/*====================================================================================*/
 /* 13 TESIMONIALS
/*====================================================================================*/
.testimonials::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #c44747b3;
	z-index: 3;
}

.testimonials {
	position: relative;
	padding:90px 0px 85px 0px; 
	background-color:#c44747;
	background: url(../images/parallax/parallax_03.jpg);
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-size:cover;
}

.image img, .text { float:left; }

.text { width:80%; margin-left:30px; margin-top:10px; }

.text .quote {
	font-family:Proxima Nova Light;
	font-size:24px;
	color:white;
}

.text p { 
	font-family:Ubuntu Med;
	color:white; 
}

.text p span {
	color:#293941;
}

.subHeader.classic .sliderControls span {
	margin-top:-250px;
	opacity:0.5;
}

.sliderControls span {
	margin-top:-170px;
	opacity:0.5;
}

.sliderControls .slider-prev, .sliderControls .slider-prev2 {
	margin-left:10px;
	float:left;
}

.sliderControls .slider-next, .sliderControls .slider-next2 {
	margin-right:10px;
	float:right;
}

.sliderControls .slider-prev:hover, .sliderControls .slider-prev2:hover {
	opacity:1.0;
}

.sliderControls .slider-next:hover, .sliderControls .slider-next2:hover {
	opacity:1.0;
}

/*====================================================================================*/
 /* 14 FOOTER
/*====================================================================================*/

footer {
	padding:60px 0px 60px 0px; 
	background-color:#4a4a4a;
	color:#ffffff;
}

.footerLogo { margin-right:15px; }

footer h4 { color:#ffffff; margin-bottom:20px; text-transform: uppercase}
footer h4 a { color:#ffffff; margin-bottom:20px; }
footer h4 a:hover { color:#c44747; }
footer p { padding-right: 20px; padding-bottom: 20px; font-size: 18px; padding-top: 20px}

.contactList { margin-top:20px; margin-bottom: 20px; padding-left: 0px; font-size: 18px }
.contactList li { margin-bottom:10px; }

.contactList li span {
	font-family:Ubuntu Bold;
	font-size:15px;
	margin-right:15px;
}

.input.footer {
	border-color:white;
	width:256px;
	padding:10px;
}

.button.subscribe.footer {
	padding:10px 25px 10px 25px;
	border-color:white;
}

.bottomBar {
	padding-top:17px;
	padding-bottom:5px;
	font-family:Ubuntu Bold;
	font-size:13px;
	color:#ffffff;
	background-color:#2f2f2f;
}

.bottomBar p { float:left; margin-top:6px; }

.socialIcons { float:right; }

.socialIcons li {
	display:inline;
	margin-right:10px;
}

.socialIcons li a img:hover { 
	opacity:0.7;
}

/** FOOTER 2 **/
#footer2 { text-align:center; }

#footer2 .socialIcons {
	float:none;
	margin:40px auto;
	margin-bottom:0px;
}

#footer2 .socialIcons li {
	border-style:solid;
	border-color:#d0dadf;
	border-radius:7px;
	padding-top:5px;
	padding-bottom:6px;
	margin-right:17px;
}

.bottomBar.center p { float:none; }
.bottomBar.center { text-align:center; }


/*====================================================================================*/
 /* 15 HOME BOXED
/*====================================================================================*/
.boxed {
	padding-left:30px;
	padding-right:30px;
}

.bodyBoxed {
	background-color:#edf1f3;
}

.navbar.navbar-default.boxed {
	padding-left:0px;
	padding-right:0px;
	border-radius:0px;
}

.workFullWidth.boxed, .features.boxed, .blogMasonry.boxed, .services3Col_big.boxed {
	background-color:white;
}

.workFullWidth.boxed {
	padding:80px 20px 50px 20px;
}

.services3Col_big.boxed, .textureLip.boxed {
	border-left-style:solid;
	border-left-width:1px;
	border-left-color:#c1c1c1;
	border-right-style:solid;
	border-right-width:1px;
	border-right-color:#c1c1c1;
}

.subHeader.classic.boxed {
	margin-top:-21px;
	padding-left:30px;
	padding-right:30px;
}

.sliderControls.boxed span {
	padding:3px 10px 3px 10px;
	border-radius:5px;
	background-color:#d2d7da;
	opacity:0.9;
}

.promoBox.boxed .input { width:310px; }

.blogPostMasonry.boxed {
	padding-left:11px;
	padding-right:11px;
	width:277px;
}

.boxed .input.footer { width:230px; }


/*====================================================================================*/
 /* 16 ANIMATIONS
/*====================================================================================*/
#screenLeft.animated {
	opacity:1;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	transition-delay: 0.3s;
	margin-top:100px;
}

#screenRight.animated {
	opacity:1;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	transition-delay: 0.5s;
	margin-top:100px;
}

#screenMiddle.animated {
	opacity:1;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	transition-delay: 0.1s;
    margin-top:-289px;
}

.featuresImg.animated {
	opacity:1;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	transition-delay: 0.2s;
    margin-top:0px;
}

.features ul.animated {
	opacity:1;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	transition-delay: 0.1s;
}

.services3Col_big .row.services.animated {
	opacity:1;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	transition-delay: 0.2s;
}

.subHeader.classic .col-lg-8.fadeInFast {
	animation: fadeIn 0.3s linear 0.5s both; 
	-webkit-animation: fadeIn 0.3s linear 0.5s both; 
	-webkit-animation-delay: 0.3s;
	-moz-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

@keyframes fadeIn{
    0% {opacity: 0; }
    100% {opacity: 1; }
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0;}
    100% {opacity: 1;}
}

.subHeader.classic .iphone.fadeInMed {
	animation: fadeInMed 0.3s linear 0.5s both; 
	-webkit-animation: fadeInMed 0.3s linear 0.5s both; 
	-webkit-animation-delay: 0.3s;
	-moz-animation-delay: 0.3s;
	animation-delay: 0.5s;
}

@keyframes fadeInMed{
    0% {opacity: 0; margin-left:-50px; }
    100% {opacity: 1; margin-left:0px; }
}

@-webkit-keyframes fadeInMed {
	0% {opacity: 0; margin-left:-50px; }
    100% {opacity: 1; margin-left:0px; }
}


